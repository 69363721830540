// Libraries
import React, { useState, useEffect, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { 
  Box, 
  IconButton, 
  Chip, 
  List, 
  ListItem, 
  ListItemText, 
  Typography, 
  Tooltip, 
  Divider, 
  CircularProgress 
} from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

// Local
import { GET_PAST_SEARCHES } from '../../../../Queries';
import { DocumentContext } from '../../../DocumentContext';
import { filterParser, decodeFilter } from './KeywordFilteringScripts'


const PastSearches = ({ setModal, setFilters, collapsedToggle }) => {
  const context = useContext(DocumentContext);
  const [pastSearches, setPastSearches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // Lazy query to fetch past searches
  const [getPastSearches] = useLazyQuery(GET_PAST_SEARCHES, {
    fetchPolicy: 'network-only',
    variables: {
      doc_id: context.documentId,
    },
    onCompleted: (data) => {
      setLoading(false);
      if (data && data.getPastSearches.response) {
        // Map the list of strings to the pastSearches structure
        const mappedSearches = data.getPastSearches.response.map((s) => ({
          searchTerm: s.searchTerm,
          keywords: s.keywords,
          parsedFilters: s.filters.map(decodeFilter).map(filterParser).filter(f => f !== ""),
          filters: s.filters.map(decodeFilter)
        }));
        setPastSearches(mappedSearches.reverse());
      }
    },
    onError: (err) => {
      setLoading(false);
      setError(err.message);
    }
  });

  useEffect(() => {
    setLoading(true);
    getPastSearches();
  }, [getPastSearches]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Loading past searches...</Typography>
      </Box>
    );
  }

  if (error) {
    return <Typography>Error loading past searches: {error}</Typography>;
  }

  if (pastSearches.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
        <Typography>No past searches available. Please search some papers first.</Typography>
      </Box>
    );
  }

  const configureSearch = (searchTerm, keywords, filters) => {
    setModal(false)
    context.updateContext('searchTerm', searchTerm);
    context.updateContext('keywords', keywords);
    setFilters(filters);
    if (filters.length > 0)
      collapsedToggle(true)
  }

  return (
    <Box sx={{ maxHeight: '400px', overflowY: 'auto', padding: '8px' }}>
      <Divider sx={{ backgroundColor: '1px solid lightgrey' }} />
      <List>
        {pastSearches.map((search, index) => (
          <React.Fragment key={index}>
            <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Tooltip 
                  title={search.searchTerm}
                  enterDelay={2000}
                  enterNextDelay={2000}
                >
                  <ListItemText
                    primary={
                      <Typography 
                        variant="h6" 
                        sx={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          WebkitLineClamp: 3, // Limit to 3 lines
                        }}
                      >
                        {search.searchTerm}
                      </Typography>
                    }
                    sx={{ marginBottom: 0 }}
                  />
                </Tooltip>
                <Tooltip 
                  title={"Fill the search form with this past search"}
                  enterDelay={1000}
                  enterNextDelay={1000}
                >
                  <IconButton 
                    edge="end" 
                    aria-label="search"
                    onClick={() => configureSearch(search.searchTerm, search.keywords, search.filters)}
                  >
                    <ManageSearchIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px', mt: 1 }}>
                {search.keywords.map((keyword, idx) => (
                  <Chip key={idx} label={keyword} variant="outlined" />
                ))}
                {search.parsedFilters && search.parsedFilters.length > 0 && search.keywords.length > 0 && (
                  <Divider orientation="vertical" flexItem sx={{ mx: 1, backgroundColor: '1px solid lightgrey' }} />
                )}
                {search.parsedFilters && search.parsedFilters.map((parsedFilter, idx) => (
                  <Chip key={`parsedFilter-${idx}`} label={parsedFilter} variant="outlined" />
                ))}
              </Box>
            </ListItem>
            {index < pastSearches.length - 1 && (
              <Divider sx={{ padding: '5px', backgroundColor: '1px solid lightgrey' }} />
            )}
          </React.Fragment>
        ))}
      </List>
      <Divider sx={{ backgroundColor: '1px solid lightgrey' }} />
    </Box>
  );
};

export default PastSearches;
